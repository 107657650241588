<template>
  <viewer
    ref="viewer"
    v-if="photoArray"
    eagle-photo-viewer="root"
  >
    <img
      v-for="(photoObject, index) in photoArray"
      :key="index"
      :alt="photoObject.name"
      :src="getPhotoUrl(photoObject)"
    >
  </viewer>
</template>

<script>
export default {
  props: {
    photo: {
      type: [Object, Array],
      default: () => null,
    },
  },
  methods: {
    active(index = 0) {
      $(this.$el)
        .find(`img:eq(${index})`)
        .click()
    },
    getPhotoUrl(photoObject) {
      return this.$helper.getPhotoUrl(photoObject, ['middle', 'small', 'tiny'])
    },
  },
  computed: {
    photoArray() {
      if(Array.isArray(this.photo)) return this.photo
      if(typeof this.photo == 'object') return [this.photo]
      return null
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
div[eagle-photo-viewer="root"]
  position: fixed
  opacity: 0
  top: -10000
  left: -10000
  width: 0
  height: 0
  pointer-events: none
</style>